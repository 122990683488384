import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
  history: createWebHistory(""),
  routes: [
    {
      path: "/",
      name: "main",
      component: () => import("../components/main.vue"),
    },
    {
      path: "/privacy-policy",
      name: "privacy-policy",
      component: () => import("../components/privacy-policy.vue"),
    },
    {
      path: "/privacy-policy/20231107",
      name: "privacy-policy-v20231107",
      component: () => import("../components/privacy-policy-20231107.vue"),
    },
    {
      path: "/term",
      name: "term",
      component: () => import("../components/term.vue"),
    },
    {
      path: "/term-bbs",
      name: "term-bbs",
      component: () => import("../components/term-bbs.vue"),
    },
    {
      path: "/turnip-prices",
      name: "turnip-prices",
      component: () => import("../components/turnip-prices.vue"),
    },
    {
      path: "/bbsctt/list/:page?",
      name: "bbsctt-list-page",
      component: () => import("../components/bbsctt-list.vue"),
    },
    {
      path: "/bbsctt/view/:idx/:page?",
      name: "bbsctt-view",
      component: () => import("../components/bbsctt-view.vue"),
    },
    {
      path: "/bbsctt/regist",
      name: "bbsctt-regist",
      component: () => import("../components/bbsctt-form.vue"),
    },
    {
      path: "/bbsctt/update/:idx/:page?",
      name: "bbsctt-update",
      component: () => import("../components/bbsctt-form.vue"),
    },
  ],
});

export default router;