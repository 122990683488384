// src/store/index.js
import { createStore } from 'vuex';

export default createStore({
  state: {
    isAuthenticated: false,
    bbscttIdx: null,
  },
  mutations: {
    setAuthentication(state, { isAuthenticated, bbscttIdx }) {
      state.isAuthenticated = isAuthenticated;
      state.bbscttIdx = bbscttIdx;
    },
  },
  actions: {
    bbscttLogin({ commit }, bbscttIdx) {
      // 로그인 로직
      commit('setAuthentication', { isAuthenticated: true, bbscttIdx });
    },
    bbscttLogout({ commit }) {
      // 로그아웃 로직
      commit('setAuthentication', { isAuthenticated: false, bbscttIdx: null });
    },
  },
  getters: {
    isAuthenticated: state => state.isAuthenticated,
    bbscttIdx: state => state.bbscttIdx,
  },
});
